<template>
    <div class="about">
        <question-record-z-g ref="questionRecordZG"></question-record-z-g>
    </div>
</template>
<style>
    body{
        /*弹窗不设置此项无法出现滚动条*/
        overflow: auto;
    }
</style>
<script>
import QuestionRecordZG from "@/components/QuestionRecordZG";
import {request} from "../../../../public/js/common-vue";

export default {
      components: {QuestionRecordZG},
        mounted() {
            this.$refs.questionRecordZG.initData(request("id"));
        },
    };
</script>
